<template>
  <ValidationObserver ref="form" v-slot="{ handleSubmit }">
    <form class="mt-2 " novalidate @submit.prevent="handleSubmit(onSubmit)">
      <ValidationProvider vid="email" name="E-mail" rules="required|email" v-slot="{ errors }">
        <div class="form-group">
          <label for="emailInput" class="label-login">User Name</label>
          <input type="email" :class="'form-control mb-0 ' +(errors.length > 0 ? ' is-invalid' : '')"
                 id="emailInput" aria-describedby="emailHelp"
                 v-model="user.email" :placeholder="$t('global.email')" required>
          <div class="invalid-feedback">
            <span>{{ errors[0] }}</span>
          </div>
        </div>
      </ValidationProvider>
      <ValidationProvider vid="password" name="Password" rules="required" v-slot="{ errors }">
        <div class="form-group">
          <label for="passwordInput" class="label-login">{{ $t('global.password') }}</label>
          <!-- <router-link to="/auth/password-reset1" class="float-right">
            {{ $t('auth.forgotPassword') }}
          </router-link> -->
          <input type="password" :class="'form-control mb-0' +(errors.length > 0 ? ' is-invalid' : '')"
                 id="passwordInput"
                 v-model="user.password" :placeholder="$t('global.password')" required>
          <div class="invalid-feedback">
            <span>{{ errors[0] }}</span>
          </div>
        </div>
      </ValidationProvider>
      <router-link to="" class="forget-pass">Forget password ?</router-link>
      <div class="d-inline-block w-100">
        <!-- <div class="custom-control custom-checkbox d-inline-block mt-2 py-1"> -->

          <!-- <input type="checkbox" class="custom-control-input" id="rememberMe">
          <label class="custom-control-label" for="rememberMe">{{ $t('auth.rememberMe') }}</label> -->
        <!-- </div> -->

        <button type="submit" class="btn btn-login my-3" :disabled="loading">
          <b-spinner v-if="loading" small></b-spinner>
          <!-- <router-link class="" to="/"></router-link> --> {{ $t('auth.login') }}
        </button>
      </div>
      <!-- <div class="sign-info">
          <span class="dark-color d-inline-block line-height-2">
            {{ $t('auth.dontHaveAccount') }}
            <router-link :to="{ name: 'auth.sign-up' }" class="iq-waves-effect pr-4">
              {{ $t('auth.signUp') }}
            </router-link>
          </span>
      </div> -->
    </form>
  </ValidationObserver>
</template>

<script>
import auth from '../../../../services/auth'

export default {
  props: ['email', 'password'],
  data: () => ({
    loading: false,
    user: {
      email: '',
      password: ''
    }
  }),
  mounted () {
    this.user.email = this.$props.email
    this.user.password = this.$props.password
  },
  methods: {
    onSubmit () {
      this.loading = true
      auth.login(this.user).then(response => {
        if (response.status) {
          localStorage.setItem('access_token', response.data.data.token)
          localStorage.setItem('user', JSON.stringify(response.data.data))
          this.$router.push({ name: 'Dashboard' })
        }
      }).finally(() => { this.loading = false })
    }
  }
}
</script>

<style lang="scss" scoped>
.forget-pass{
  color: white;
}
.btn-login{
  width: 100%;
  padding: 20px !important;
  color: white;
  background-image: linear-gradient(to left,#0097b5,#00cbdc, #34d54f);
}
.form-group .label-login{
  color: white;
}
.form-control{
  padding: 7px !important;
  margin: 0px !important;
}
</style>
