<template>
  <div class="auth-inputs">
    <h1 class="text-center login ">{{ $t('auth.login') }}</h1>
    <tab-content id="pills-tabContent-1" class="mt-0">
      <tab-content-item :active="true" id="pills-jwt-fill" aria-labelled-by="pills-jwt-tab-fill">
          <sign-in1-form username="" password=""></sign-in1-form>
      </tab-content-item>
    </tab-content>
  </div>
</template>

<script>
import SignIn1Form from './Forms/SignIn1Form'

export default {
  name: 'SignIn1',
  components: { SignIn1Form },
  data: () => ({}),
  mounted () {
    const loggedIn = localStorage.getItem('access_token')
    if (loggedIn !== undefined && loggedIn !== null) {
      this.$router.push({ name: 'Dashboard' })
    }
  }
}
</script>

<style>
.login{
  color: white;
}
</style>
