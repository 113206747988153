import { singleSignOn } from '../axios'

export default {
  login (userData) {
    return singleSignOn().post('login', userData)
  },
  register (userData) {
    return singleSignOn().post('/admin/signup', userData)
  }
  // getUserdetails (token = '') {
  //   return singleSignOn(token).get('/admin/whoami')
  // }
}
